import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Grid, Modal } from '@mui/material'
import { Strings } from '../../Strings'
import { checkNull } from '../../Helpers'

/**
 * Renders a component to display payment status information.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.status - The payment status (e.g., "success", "fail").
 * @param {string} props.message - The message associated with the payment status.
 * @param {boolean} props.sm - A flag indicating whether to display the component in a small size.
 * @returns {JSX.Element} The rendered PaymentStatus component.
 */
const PaymentStatus = (props) => {
  const { status, message, sm, primaryColor } = props
  const isLoading = status === 'loading' || status === 'url'
  const payFail = status === 'fail'
  const paySuccessFull = status === 'success'
  const isWait = status === 'wait'
  const requiresAction = status == Strings.requires_action
  const cancel = status === 'cancel'
  const subscriptionSuccess = status === Strings.subscriptionSuccess
  const updatePayMethod = status === Strings.updatePayMethod
  const subCreated = status === 'Subscription Pending'
  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <div
            style={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CircularProgress
              sx={{
                mb: 3,
              }}
              color='primary'
              style={{
                fontSize: '100px !important',
                color: primaryColor,
              }}
            />
            <Typography
              fontWeight={600}
              sx={{
                mb: 1,
                fontSize: sm ? '22px' : '24px',
              }}
            >
              Processing Payment
            </Typography>
            <Typography
              color='#4b4b4b !important'
              fontSize={sm ? '18px' : '20px'}
              textAlign='center'
            >
              Please be patient. this process might take some time,
              {!sm && <br />}
              please do not hit refresh or browser back button or close this
              window
            </Typography>
          </div>
        ) : (
          <div
            style={{
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              src={
                paySuccessFull || subscriptionSuccess || updatePayMethod
                  ? 'https://aautimpwebdev.blob.core.windows.net/logo/success.png'
                  : isWait
                  ? 'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1701334510-8090022.png'
                  : requiresAction || subCreated
                  ? 'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1701334510-8090022.png'
                  : 'https://aautimpwebdev.blob.core.windows.net/logo/fail.png'
              }
              alt='icon'
              height={sm ? 90 : 100}
              style={{
                marginBottom: '10px',
                color: primaryColor,
              }}
            />
            {!subscriptionSuccess && !updatePayMethod && (
              <Typography
                fontWeight={600}
                sx={{
                  mb: 1,
                  fontSize: sm ? '22px' : '24px',
                }}
              >
                {paySuccessFull
                  ? `Payment Successful!`
                  : isWait
                  ? ``
                  : requiresAction
                  ? ``
                  : subCreated
                  ? status
                  : cancel
                  ? `Payment Cancelled`
                  : payFail
                  ? `Payment Failed !`
                  : status ?? `Payment Failed !`}
              </Typography>
            )}
            <Typography
              color='#4b4b4b !important'
              fontSize={sm ? '18px' : '20px'}
            >
              {paySuccessFull
                ? `Your payment has been successful!`
                : subscriptionSuccess
                ? Strings.subscriptionSuccess
                : updatePayMethod
                ? checkNull(message)
                  ? Strings.updatePayMethod
                  : message
                : isWait
                ? `${
                    message ||
                    'Your payment is in process. It will take 4-5 days for completion'
                  }`
                : requiresAction
                ? `${message || Strings.requiresActionMsg}`
                : payFail
                ? `${message || 'Payment Failed !'}`
                : subCreated
                ? message
                : `${
                    message ||
                    'Any amount deducted will be refunded within 7 days'
                  }`}
            </Typography>
          </div>
        )}
      </div>

      {status === 'url' && (
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 10,
          }}
          open={true}
        >
          <Grid
            container
            style={{
              maxWidth: 650,
              maxHeight: 650,
              minHeight: 400,
              backgroundColor: 'white',
              padding: 10,
              borderRadius: 4,
            }}
          >
            <iframe
              id='myIframe'
              src={message}
              referrerPolicy='unsafe-url'
              style={{ height: 'auto', width: '100%', border: 'none' }}
              title='3D secure'
            ></iframe>
          </Grid>
        </Modal>
      )}
    </>
  )
}

export default PaymentStatus
