import { useEffect, useState } from "react";
import PaymentAggregator from "../PaymentAggregator";
import { getApiDataProgressPayment, liveUrl } from "../APIHelper";
import PaymentStatus from "../PaymentStatus";
import { CircularProgress, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { checkNull } from "../../Helpers";

const HomePage = () => {
  const primaryColor = "#0068EF";
  let params = new URL(document.location.toString()).searchParams;
  let id = params.get("id");
  const [data, setData] = useState({
    payObj: {},
    status: false,
    failMessage: "",
    initialStatus: false,
  });

  async function callApi() {
    setData((prev) => ({ ...prev, status: true }));
    //  setPageLoader(true)
    try {
      // Step 3: Make an asynchronous API request to the login endpoint
      const response = await getApiDataProgressPayment(
        `${liveUrl}getIframe/${id}`, // URL for the login endpoint
        "GET"
      );
      console.log("ressss", response);
      if (response?.status == false) {
        //  setPageLoader(false)
        setData((prev) => ({ ...prev, status: false }));
      } else {
        setData((prev) => ({ ...prev, payObj: response?.data }));
        setData((prev) => ({ ...prev, status: false }));
      }
    } catch (error) {
      setData((prev) => ({
        ...prev,
        initialStatus: true,
        failMessage: error?.message,
      }));
      console.log("error:", error);
    }
  }
  useEffect(() => {
    setData((prev) => ({ ...prev, status: true }));
    let timeout;
    timeout = setTimeout(() => {
      setData((prev) => ({ ...prev, initialStatus: false }));
      if (!isEmpty(params.get("id"))) {
        callApi();
      } else {
        setData((prev) => ({ ...prev, initialStatus: true, status: false }));
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  console.log(
    "checkkk",
    !isEmpty(data?.payObj) && Object.keys?.(data?.payObj)?.length > 0
  );

  return (
    <>
      {data?.status ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CircularProgress size={30} style={{ color: primaryColor }} />
          </div>
        </div>
      ) : !isEmpty(data?.payObj) && Object.keys?.(data?.payObj)?.length > 0 ? (
        <PaymentAggregator {...data?.payObj} />
      ) : (
        <>
          {data?.initialStatus ? (
            <div
              style={{
                display: "flex",
                height: "100vh",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <PaymentStatus
                primaryColor={primaryColor}
                status={"fail"}
                message={
                  checkNull(data?.failMessage)
                    ? "Authentication Failed"
                    : data?.failMessage
                }
              />
            </div>
          ) : isEmpty(data?.payObj) && !data?.initialStatus ? (
            <div
              style={{
                display: "flex",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                No Data
              </Typography>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default HomePage;
