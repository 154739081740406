export const Strings = {
  requiresActionMsg:
    'Please check in orders and verify your bank account to ensure successful payment.',
  verifyPending: 'Verification Pending',
  requires_action: 'requires_action',
  succeeded: 'succeeded',
  requires_payment_method: 'requires_payment_method',
  subscriptionSuccess: 'Subscription created successfully',
  updatePayMethod: 'Payment Method updated successfully',
  cancel: 'cancel',
  paymentMethod: 'paymentMethod',
  resume: 'resume',
  pause: 'pause',
  subscription: 'subscription',
  updateFailed: 'Update Failed',
  startSub: 'Start Subscription',
  updateMethod: 'Update Payment Method',
  subPending: 'subscription pending',
  payPending: 'payment pending',
}
