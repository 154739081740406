import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  gradientText: {
    background: 'linear-gradient(124deg, #0BBCED, #1252A5)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: '#0000',
  },
  input: {
    width: '100%',
    color: '#9D9D9D',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& label.Mui-focused': {
      color: '#0068EF',
    },

    '& label.Mui-error': {
      color: 'red',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: '#0068EF',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#0068EF',
      },

      '&.Mui-error fieldset': {
        borderColor: 'red',
      },
    },
  },
  payButton: {
    marginTop: '10px !important',
    width: 200,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    color: '#FFFFFF !important',
    fontWeight: 'bold !important',
  },
  pricingBreakdown: {
    margin: '10px 0px 0px 0px',
    textTransform: 'capitalize',
  },
  pricingBreakdown_final: {
    marginTop: '20px !important',
    borderTop: '1px solid #c6c6c6',
    backgroundColor: '#F5F9FF',
    padding: 15,
  },
  pricingBreakdown_amount: {
    fontWeight: 'bold',
  },
  cardWidth: {
    width: '100%',
    '@media (min-width:1024px)': {
      width: '60%',
    },
  },
}))

export default useStyles
