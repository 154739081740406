export const Constants = {
  visa: 'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1691126059-visa-logo.png',
  masterCard:
    'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1691126090-Mastercard-logo.png',
  americanExpress:
    'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1691132025-American-Express-Logo-Background-PNG-Image.png',
  discover:
    'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1691126118-Discover-logo.png',
  jcb: 'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1691126271-JCB.png',
  dinersClub:
    'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1691126219-Diners-club.png',
  ruPay:
    'https://groovyspace.fra1.digitaloceanspaces.com/aauti/1691126024-Rupay.png',
}
