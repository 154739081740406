export function checkNull(value) {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    value === 'null' ||
    value?.length === 0
  )
}

export function capitalizeWordFirstLetter(string) {
  var grammar = ['a', 'an', 'the', 'and', 'or', 'of', 'are', 'for']
  if (!string) return string
  var capString = string.toLowerCase()
  capString = capString
    .split(' ')
    .map(function (word) {
      return !grammar.includes(word)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word
    })
    .join(' ')
  const newString = capString?.[0]?.toUpperCase() + capString?.slice(1)
  return newString
}

export function btoa(input) {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let str = input
  let output = ''

  for (
    let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || ((map = '='), i % 1);
    output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
  ) {
    charCode = str.charCodeAt((i += 3 / 4))

    if (charCode > 0xff) {
      throw new Error(
        "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
      )
    }

    block = (block << 8) | charCode
  }

  return output
}
