import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Snackbar,
  TextField,
  Modal,
  useMediaQuery,
  InputAdornment,
} from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import GppGoodIcon from '@mui/icons-material/GppGoodOutlined'
import useStyles from './style'
import { FormControl } from '@mui/material'
import { currency_symbol } from '../staticData'
import { getApiDataProgressPayment } from '../APIHelper'
import { cloneDeep, isArray, isEmpty, isUndefined } from 'lodash'
import creditCardType from 'card-validator'
import { NumberFormatBase, PatternFormat } from 'react-number-format'
import CryptoJS from 'crypto-js'
import ConfirmDialog from '../ConfirmDialog'
import axios from 'axios'
import {
  InfoOutlined,
  AccountBalance,
  CancelOutlined,
  Cancel,
  AccountCircle,
} from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import { Constants } from './Constants'
import { Strings } from '../../Strings'
import { btoa, checkNull } from '../../Helpers'
import moment from 'moment'

/**
 * CustomCard React component for handling custom card payments.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.paymentType - The type of payment (e.g., "Credit Card", "Debit Card").
 * @param {string} props.type - The type of the payment method.
 * @param {Object} props.paymentData - Data related to the payment process.
 * @param {Object} props.cardBrandSelect - Selected card brand information.
 * @param {Object} props.chargeData - Data related to the charge process.
 * @param {string} props.liveUrl - The live URL for making requests.
 * @param {function} props.setTabValue - Function to set the tab value.
 * @param {boolean} props.noCharge - Boolean indicating whether there is no additional charge.
 * @param {function} props.setPaySuccess - Function to set the payment success state.
 * @param {function} props.onPaymentDone - Callback function triggered when payment is done.
 * @param {string} props.linearColorsName - Name of linear colors used in the UI.
 * @param {boolean} props.isGradientButton - Boolean indicating whether to use gradient buttons.
 * @param {function} props.changeBtnText - Function to change the button text.
 * @returns {React.ReactNode} The JSX representation of the CustomCard component.
 */
const CustomCard = (props) => {
  const {
    paymentType,
    type,
    paymentData,
    cardBrandSelect,
    chargeData,
    liveUrl,
    setTabValue,
    noCharge,
    setPaySuccess,
    onPaymentDone,
    linearColorsName,
    isGradientButton,
    changeBtnText,
    primaryColor,
  } = props

  const styles = useStyles()
  const [paymentObj, setPaymentObj] = useState({})
  const [showBreakdown, setShowBreakdown] = useState(false)
  const [mainLoader, setMainLoader] = useState(false)

  const [cardNumber, setCardNumber] = useState('')
  const [cvv, setCvv] = useState('')
  const [expDate, setExpDate] = useState('')
  const [enteredCard, setEnteredCard] = useState('')
  const [cardType, setCardType] = useState('visa-or-mastercard')
  const [cardErr, setCardErr] = useState({
    card: false,
    cvv: false,
    expire: false,
  })

  const [listLoader, setListLoader] = useState('')
  const [originalText, setOriginalText] = useState({})

  const [cardList, setCardList] = useState([])
  const [cusMainID, setCusID] = useState('')

  const Ref = useRef(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [confirmSaveDialog, setConfirmSaveDialog] = useState(false)
  const [cnfmLoader, setCnfmLoader] = useState(false)
  const [confirmType, setConfirmType] = useState({})
  const [confirmSaveType, setConfirmSaveType] = useState({})

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const openPopover = Boolean(anchorEl)
  const PopoverID = openPopover ? 'simple-popover' : undefined
  const [longPressData, setLongPressData] = useState({})
  const displayName = longPressData?.gateway_name?.replaceAll('_', ' ')

  const [checked, setChecked] = useState(false)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const isRecurring =
    paymentData?.payThroughRecurrence || paymentData?.payThroughEMI

  const currentDomain = window.location

  const updateSubPayMethod = paymentData?.updateSubscription
    ? paymentData?.updateSubscriptionType === Strings.paymentMethod
      ? true
      : false
    : false

  const handlePopoverOpen = (event) => {
    setAnchorEl(event)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const isDisable =
    type !== 'ACH'
      ? isEmpty(cardNumber) ||
        isEmpty(cvv) ||
        isEmpty(expDate) ||
        cardErr?.card ||
        cardErr?.cvv ||
        cardErr?.expire
      : // ||
        //   !isEmpty(enteredCard)
        false

  const paymentGatwayFee = (
    cardBrandSelect?.charge_object?.charges_obj?.final_amount -
    chargeData?.withChargeAmount
  )?.toFixed(2)

  const finalAmount = noCharge
    ? paymentData?.amount
    : // : chargeData?.isPaymentGateWay
      // ? cardBrandSelect?.charge_object?.charges_obj?.final_amount
      chargeData?.withChargeAmount

  useEffect(() => {
    resetData()
    !isEmpty(cardBrandSelect) && getCardList()

    if (cardBrandSelect && cardBrandSelect.extra_data) {
      let bytes = CryptoJS.AES.decrypt(
        cardBrandSelect?.extra_data,
        '470cb677d807b1e0017c50b'
      )
      let originalText1 = JSON?.parse(bytes.toString(CryptoJS.enc.Utf8))

      setOriginalText(originalText1)
    }

    type === 'ACH' && setPaymentObj(cloneDeep(cardBrandSelect?.ach_fields))
  }, [cardBrandSelect])

  function resetData() {
    setCardNumber('')
    setCvv('')
    setExpDate('')
    setEnteredCard('')
    setCardType('visa-or-mastercard')
    setCardErr({
      card: false,
      cvv: false,
      expire: false,
    })
  }

  // Function to create a token using the Stripe API
  const createTokenStripe = async (cusID) => {
    // Set payment success status to "loading" to indicate an ongoing process
    setPaySuccess('loading')
    // Retrieve the Stripe secret and public keys from the originalText object
    const stripeSecretKey = originalText?.private_key
    const stripePublicKey = originalText?.public_key
    // Determine the card expiration date using the creditCardType library
    const cardType1 = creditCardType.expirationDate(expDate)

    // Prepare card data for the API request
    const cardData = {
      'card[number]': cardNumber.replaceAll(' ', ''),
      'card[exp_month]': cardType1?.month,
      'card[exp_year]': cardType1?.year,
      'card[cvc]': cvv,
    }
    // Make a POST request to create a token using the Stripe API

    fetch('https://api.stripe.com/v1/tokens', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${stripePublicKey}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(cardData).toString(),
    })
      .then((response) => {
        // Check if the response is successful (HTTP status code 2xx)
        if (!response.ok) {
          // If not successful, set payment success status to "fail" and set a timeout to reset it after 5 seconds
          setPaySuccess('fail')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
          // Throw an error to trigger the catch block
          throw new Error('Failed to create Stripe token')
        }
        // If successful, parse the response as JSON and return it
        return response.json()
      })
      .then((data) => {
        // After successfully creating the token, save the order using the obtained token
        if (updateSubPayMethod) {
          updatePaymentMethod(data?.id, 'stripe', '', 1)
        } else {
          SaveOrder(data?.id, 'stripe', '', 1)
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the API request
        console.error('Error creating token:', error)
        // Set payment success status to "fail" and set a timeout to reset it after 5 seconds
        setPaySuccess('fail')
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      })
  }

  // Asynchronous function to save an order
  /**
   * Asynchronously saves the order and handles subsequent actions based on the payment type.
   *
   * @param {string} token - Token associated with the payment method.
   * @param {string} type - Type of payment method (e.g., "adyen", "braintree", "paypal").
   * @param {string} cusID - Customer ID.
   * @param {boolean} isNew - Indicates whether it's a new order.
   */
  async function SaveOrder(token, type, cusID, isNew) {
    setPaySuccess('loading')
    try {
      const unit_amount =
        cardBrandSelect?.charge_object?.charges_obj?.final_amount
      let data = {
        name: paymentData?.name,
        amount: paymentData?.payThroughEMI
          ? chargeData?.withChargeAmount /
            paymentData?.recurrenceObj?.interval_count
          : chargeData?.withChargeAmount,
        //amount will be split in interval_count if payment is through EMI
        final_amount: paymentData?.payThroughEMI
          ? unit_amount / paymentData?.recurrenceObj?.interval_count
          : unit_amount,
        app_token: paymentData?.app_token,
        country_id: cardBrandSelect?.country_id,
        currency: paymentData?.currency,
        mode: chargeData?.mode,
        payment_method_id: cardBrandSelect?.payment_method_id,
        payment_sub_method_id: cardBrandSelect?.payment_sub_method_id,
        transaction_code: paymentData?.transaction_code,
        gateway_code: cardBrandSelect?.charge_object?.gateway_code,
        gateway_id: cardBrandSelect?.gateway_id,
        payment_gateway_fee: noCharge
          ? 'inclusive'
          : chargeData?.isPaymentGateWay
          ? 'exclusive'
          : 'inclusive',
        email: paymentData?.email,
        base_amount: paymentData?.amount,
        charge_id: cardBrandSelect?.charge_object?.charges_obj?.id,
        platform: 'web',
        charges_json: JSON.stringify(chargeData?.mainChargeData),
        mobile: paymentData?.mobile,
        is_subscription: isRecurring,
      }
      if (isRecurring) {
        let details = paymentData?.recurrenceObj
        let intervalType =
          paymentData?.country_code === 'US'
            ? details?.intervalType?.slice(0, -2)
            : details?.intervalType
        data.recurring_details = JSON.stringify({
          interval: intervalType,
          interval_count:
            checkNull(details?.interval_count) || details?.interval_count <= 0
              ? 1
              : details?.interval_count,
        })
        data.start_date = moment(
          checkNull(details?.start_at) ? new Date() : details?.start_at
        ).unix()
        data.iterations = checkNull(details?.iterations)
          ? 0
          : details?.iterations
      }
      // Make a POST request to save the order using the getApiDataProgressPayment function
      const response = await getApiDataProgressPayment(
        `${liveUrl}save-order`,
        'POST',
        JSON.stringify(data),
        chargeData?.auth_token
      )
      // Check if the order save operation was successful
      if (response?.status == false) {
        // If not successful, set payment success status to "fail" with an error message
        setPaySuccess('fail', response?.message)
        // Set a timeout to reset the payment success status after 5 seconds
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else {
        // If successful, handle subsequent actions based on the payment type

        // Check if the payment type is Adyen or Braintree
        if (type === 'adyen' || type === 'braintree') {
          // Call the CreateToken function to handle token creation
          CreateToken(response?.code, type, isNew)
        }
        // Check if the payment type is PayPal
        else if (type === 'paypal') {
          // Call the makePaymentPaypal function to handle PayPal payment
          makePaymentPaypal(response?.code)
        } else {
          // For other payment types, call the paymentApi function to handle the payment process
          if (isRecurring) {
            subscriptionApi(token, type, cusID, isNew, response?.code)
          } else {
            paymentApi(token, type, cusID, isNew, response?.code)
          }
        }
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  // Asynchronous function to handle payment processing
  /**
   * Asynchronously processes payments based on the provided parameters.
   *
   * @param {string} token - Token associated with the payment method.
   * @param {string} type1 - Type of payment method (e.g., "braintree", "adyen", "authorize").
   * @param {string} cusID - Customer ID.
   * @param {boolean} isNew - Indicates whether it's a new payment.
   * @param {string} code - Order code associated with the payment.
   */
  async function paymentApi(token, type1, cusID, isNew, code) {
    const cardType1 = creditCardType.expirationDate(expDate)
    // Determine if the payment type is ACH
    const isBank = type === 'ACH'
    // Determine the gateway type from the charge object
    const isType = cardBrandSelect?.charge_object?.charges_obj?.gateway_name

    // Initialize an object to store final payment data
    const data =
      type1 === 'razorpay'
        ? {
            method: 'card',
            card: {
              number: cardNumber.replaceAll(' ', ''),
              cvv: cvv,
              expiry_month: cardType1?.month,
              expiry_year: cardType1?.year,
              name: paymentData?.name,
            },
          }
        : { token }
    let final_data = {
      amount: {
        amount: paymentData?.amount,
        final_amount: cardBrandSelect?.charge_object?.charges_obj?.final_amount,
      },
      ...data,
    }
    // Check if the payment type is ACH
    if (isBank) {
      // Check if the gateway type is Adyen or Authorize.net
      if (isType === 'adyen' || isType === 'authorize_net') {
        final_data.token = token
        final_data.customer_id = !isEmpty(cusID) ? cusID : cusMainID
      } else {
        // For other ACH types, include bank information in the final data
        final_data.bank = {
          bank_id: isNew === 0 ? token : token?.bank_account?.id,
        }
        // If it's a new bank, include the token in the final data
        if (isNew === 1) {
          final_data.bank.token = token?.id
        }
      }
    } else {
      // If the payment type is not ACH, include customer ID and token in the final data
      if (type1 === 'braintree' || type1 === 'adyen' || type1 === 'authorize') {
        final_data.customer_id = cusID
      }
      // final_data.token = token
    }

    // Encrypt the final data using AES encryption
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(final_data),
      '470cb677d807b1e0017c50b'
    ).toString()

    // Prepare data for the API request
    const Ddata = {
      data: ciphertext,
      order_code: code,
      is_new: isNew,
      redirect_url: currentDomain?.origin,
      remember_me: checked ? 1 : 0,
    }

    try {
      // Make a POST request to the custom-checkout endpoint for payment processing
      const response = await getApiDataProgressPayment(
        `${liveUrl}custom-checkout`,
        'POST',
        JSON.stringify(Ddata),
        chargeData?.auth_token
      )

      // Check the response status and take appropriate actions
      if (isUndefined(response) || response?.status === false) {
        // If the response indicates an error, set payment success status to "fail" with an error message
        setPaySuccess('fail', response?.message?.toString())
        // Set a timeout to reset the payment success status after 5 seconds
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else if (response?.status === 'processing') {
        // If the status is "processing," set up an interval to check payment progress
        const interval = setInterval(() => {
          checkPaymentProgress(code)
        }, 5000)
        Ref.current = interval
      } else {
        // If the response indicates success, check if there is a URL
        if (!isEmpty(response?.data?.redirect_url)) {
          // If there is a URL, set payment success status to "url" with the URL
          setPaySuccess('url', response?.data?.redirect_url)
        } else {
          // If there is no URL, set payment success status to "success" and trigger onPaymentDone
          setPaySuccess('success')
          onPaymentDone()
        }
      }
      setListLoader('')
    } catch (error) {
      // Handle any errors that occurred during the payment processing
      console.log('📌 ⏩ file: index.js:227 ⏩ paymentApi ⏩ error:', error)
      setListLoader('')
    }
  }

  //subscription api call

  async function subscriptionApi(token, type1, cusID, isNew, code) {
    const cardType1 = creditCardType.expirationDate(expDate)
    // Determine if the payment type is ACH
    const isBank = type === 'ACH'
    // Determine the gateway type from the charge object
    const isType = cardBrandSelect?.charge_object?.charges_obj?.gateway_name

    // Initialize an object to store final payment data
    const data =
      type1 === 'razorpay'
        ? {
            method: 'card',
            card: {
              number: cardNumber.replaceAll(' ', ''),
              cvv: cvv,
              expiry_month: cardType1?.month,
              expiry_year: cardType1?.year,
              name: paymentData?.name,
            },
          }
        : { token }
    let final_data = {
      amount: {
        amount: paymentData?.amount,
        final_amount: cardBrandSelect?.charge_object?.charges_obj?.final_amount,
      },
      ...data,
    }
    // Check if the payment type is ACH
    if (isBank) {
      // Check if the gateway type is Adyen or Authorize.net
      if (isType === 'adyen' || isType === 'authorize_net') {
        final_data.token = token
        final_data.customer_id = !isEmpty(cusID) ? cusID : cusMainID
      } else {
        // For other ACH types, include bank information in the final data
        final_data.bank = {
          bank_id: isNew === 0 ? token : token?.bank_account?.id,
        }
        // If it's a new bank, include the token in the final data
        if (isNew === 1) {
          final_data.bank.token = token?.id
        }
      }
    } else {
      // If the payment type is not ACH, include customer ID and token in the final data
      if (type1 === 'braintree' || type1 === 'adyen' || type1 === 'authorize') {
        final_data.customer_id = cusID
      }
      // final_data.token = token
    }

    // Encrypt the final data using AES encryption
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(final_data),
      '470cb677d807b1e0017c50b'
    ).toString()

    // Prepare data for the API request
    const Ddata = {
      data: ciphertext,
      order_code: code,
      is_new: isNew,
      redirect_url: currentDomain?.origin,
      remember_me: checked ? 1 : 0,
      type: 'custom_subscription',
    }

    try {
      // Make a POST request to the custom-checkout endpoint for payment processing
      const response = await getApiDataProgressPayment(
        `${liveUrl}schedule-subscription-payment`,
        'POST',
        JSON.stringify(Ddata),
        chargeData?.auth_token
      )

      // Check the response status and take appropriate actions
      if (isUndefined(response) || response?.status === false) {
        // If the response indicates an error, set payment success status to "fail" with an error message
        setPaySuccess('fail', response?.message?.toString())
        // Set a timeout to reset the payment success status after 5 seconds
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else if (response?.status === 'processing') {
        // If the status is "processing," set up an interval to check payment progress
        const interval = setInterval(() => {
          checkPaymentProgress(code)
        }, 5000)
        Ref.current = interval
      } else {
        // If the response indicates success, check if there is a URL
        if (!isEmpty(response?.data?.redirect_url)) {
          // If there is a URL, set payment success status to "url" with the URL
          setPaySuccess('url', response?.data?.redirect_url)
        } else {
          // If there is no URL, set payment success status to "success" and trigger onPaymentDone
          setPaySuccess(Strings.subscriptionSuccess)
          onPaymentDone()
        }
      }
      setListLoader('')
    } catch (error) {
      // Handle any errors that occurred during the payment processing
      console.log('📌 ⏩ file: index.js:227 ⏩ paymentApi ⏩ error:', error)
      setListLoader('')
    }
  }

  // Asynchronous function to check the progress of a payment
  /**
   * Asynchronously checks the progress of a payment using the pay-response endpoint.
   *
   * @param {string} code - Order code associated with the payment.
   * @param {string} token - Token associated with the payment method.
   */
  async function checkPaymentProgress(code, token) {
    // Make a GET request to the pay-response endpoint to check payment progress
    fetch(`${liveUrl}pay-response/${code}/${paymentData?.transaction_code}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${chargeData?.auth_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // Check the payment status in the response data
        if (response?.data?.status == 'success') {
          // If the status is "success," set payment success status to "success" and trigger onPaymentDone
          setPaySuccess('success')
          onPaymentDone()
          // Clear the interval for checking payment progress
          clearInterval(Ref.current)
        } else if (response?.data?.status_3ds == 'success') {
          // If 3DS status is "success," call paymentApi for further processing and clear the interval
          paymentApi(token, 'paypal', '', 1, code)
          clearInterval(Ref.current)
        } else if (response?.data?.status == 'failed') {
          // If the status is "failed," set payment success status to "fail" with a fail reason and set a timeout to reset it after 5 seconds
          setPaySuccess('fail', response?.data?.message)
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
          // Clear the interval for checking payment progress
          clearInterval(Ref.current)
        } else if (response?.data?.status == Strings.requires_action) {
          setPaySuccess(Strings.requires_action)
          onPaymentDone()
          clearInterval(Ref.current)
        } else if (response?.data?.status == 'processing') {
          setPaySuccess('wait', response?.data?.message)
          onPaymentDone()
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
          clearInterval(Ref.current)
        } else if (response?.data?.status == 'initialized') {
          setPaySuccess('cancel', 'Payment Cancelled')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
          clearInterval(Ref.current)
        }
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors here
      })
  }

  // Asynchronous function to create an ACH token using the Stripe API
  /**
   * Asynchronously creates an ACH token using the Stripe API and initiates the payment process.
   *
   * @throws {Error} If there is an issue with the API request or the Stripe secret key is missing.
   *
   * @returns {Promise<void>} Resolves when the ACH token is successfully created and the payment process is initiated.
   */
  const createAchTokenStripe = async () => {
    // Set payment success status to "loading" to indicate an ongoing process
    setPaySuccess('loading')
    // Retrieve the Stripe secret key from the originalText object
    const stripeSecretKey = originalText?.private_key

    // Define the Stripe API endpoint for creating tokens
    const apiUrl = 'https://api.stripe.com/v1/tokens'

    // Create a URLSearchParams object containing the ACH information
    const body = new URLSearchParams({
      'bank_account[country]': paymentData?.country_code,
      'bank_account[currency]': paymentData?.currency,
      'bank_account[account_holder_name]':
        paymentObj?.account_holder_name?.answer,
      'bank_account[account_holder_type]':
        paymentObj?.account_holder_type?.answer,
      'bank_account[routing_number]': paymentObj?.routing_number?.answer,
      'bank_account[account_number]': paymentObj?.account_number?.answer,
    })

    // Create headers with the Stripe secret key and content type
    const headers = new Headers({
      Authorization: `Bearer ${stripeSecretKey}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    })

    fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body: body.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        SaveOrder(data, 'stripe', '', 1)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  // Function to generate a random alphanumeric string
  /**
   * Generates a random string of alphanumeric characters.
   *
   * @returns {string} A random string of length 10 composed of alphanumeric characters (0-9, A-Z, a-z).
   */
  function generateRandomString() {
    // Define the set of characters from which the random string will be generated
    const characters =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let randomString = ''

    // Loop to generate each character of the random string
    for (let i = 0; i < 10; i++) {
      // Generate a random index within the length of the characters set
      const randomIndex = Math.floor(Math.random() * characters.length)
      // Append the character at the random index to the randomString
      randomString += characters.charAt(randomIndex)
    }

    return randomString
  }

  // Asynchronous function to create a payment token
  /**
   * Creates a payment token (card or ACH) and initiates the payment process.
   *
   * @param {string} code - The order code associated with the payment.
   * @param {string} type1 - The payment type (e.g., "braintree").
   * @param {boolean} isNew - Indicates whether it's a new payment method.
   *
   * @returns {Promise<void>} A Promise that resolves when the payment token is successfully created.
   */
  async function CreateToken(code, type1, isNew) {
    // Set payment success status to "loading" to indicate an ongoing process
    setPaySuccess('loading')

    // Determine the prefix based on the payment type and mode
    const preFix =
      type1 === 'braintree' || chargeData?.mode === 'live' ? '' : 'test_'
    // Extract card expiration details
    const cardType1 = creditCardType.expirationDate(expDate)
    // Get the first two digits of the current year
    const currentYear = new Date().getFullYear()
    const firstTwoDigits = currentYear.toString().slice(0, 2)

    // Dummy data for card or ACH based on the payment type
    const dummy_data = {
      card_number: `${preFix}${cardNumber.replaceAll(' ', '')}`,
      card_expiry_month: `${preFix}${cardType1?.month}`,
      card_expiry_year: `${preFix}${firstTwoDigits}${cardType1?.year}`,
      card_cvv: `${preFix}${cvv}`,
    }

    const ACH_data = {
      account_number: paymentObj?.account_number?.answer,
      routing_number: paymentObj?.routing_number?.answer,
      account_holder_name: paymentObj?.account_holder_name?.answer,
    }

    // Encrypt card or ACH data using AES encryption
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(type === 'ACH' ? ACH_data : dummy_data),
      '470cb677d807b1e0017c50b'
    ).toString()

    // Prepare final data for the API request
    const finalData = {
      order_code: code,
      encrypted_data: ciphertext,
    }

    try {
      // Make a POST request to generate a card token or ACH token
      const response = await getApiDataProgressPayment(
        `${liveUrl}generate-card-token`,
        'POST',
        JSON.stringify(finalData),
        chargeData?.auth_token
      )
      // Check the response status and take appropriate actions
      if (response?.status == false) {
        // If the response indicates an error, set payment success status to "fail" with an error message
        setPaySuccess('fail', response?.message)
        // Set a timeout to reset the payment success status after 5 seconds
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else {
        // If successful, call the paymentApi function with the obtained token and customer ID
        paymentApi(
          response?.data?.token,
          type1,
          response?.data?.customerId,
          isNew,
          code
        )
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.log('error:', error)
    }
  }

  // Asynchronous function to obtain a customer profile ID from Authorize.Net
  /**
   * Initiates the process to obtain an Authorize.Net customer profile ID and creates a payment token.
   * This function is specific to bank account payments using Authorize.Net.
   *
   * @returns {Promise<void>} A Promise that resolves when the process is completed.
   */
  async function getAuthorizeTrasId() {
    // Set payment success status to "loading" to indicate an ongoing process
    setPaySuccess('loading')

    // Retrieve API credentials from the originalText object
    const API_LOGIN_ID = originalText?.public_key
    const TRANSACTION_KEY = originalText?.private_key

    // Set the API endpoint URL based on the chargeData mode
    const API_URL =
      chargeData?.mode === 'test'
        ? 'https://apitest.authorize.net/xml/v1/request.api'
        : 'https://api.authorize.net/xml/v1/request.api'

    // Prepare the XML request data with dynamic values
    const requestData = `
      <?xml version="1.0" encoding="UTF-8"?>
      <createCustomerProfileRequest xmlns="AnetApi/xml/v1/schema/AnetApiSchema.xsd"> 
      <merchantAuthentication>
      <name>${API_LOGIN_ID}</name>
      <transactionKey>${TRANSACTION_KEY}</transactionKey>
      </merchantAuthentication>
      <profile>
        <merchantCustomerId>${generateRandomString()}</merchantCustomerId>
        <description>Profile description here</description>
        <email>${paymentData?.email}</email>
        <paymentProfiles>
          <customerType>individual</customerType>
          <payment>
            <bankAccount>
              <accountType>${paymentObj?.account_holder_type?.answer?.toLowerCase()}</accountType>
              <routingNumber>${
                paymentObj?.routing_number?.answer
              }</routingNumber>
              <accountNumber>${
                paymentObj?.account_number?.answer
              }</accountNumber>
              <nameOnAccount>${
                paymentObj?.account_holder_name?.answer
              }</nameOnAccount>
            </bankAccount>
          </payment>
        </paymentProfiles>
      </profile>
    <validationMode>testMode</validationMode>
    </createCustomerProfileRequest>
      `

    try {
      // Make a POST request to the Authorize.Net API to create a customer profile
      const response = await axios.post(API_URL, requestData, {
        headers: {
          'Content-Type': 'text/xml',
        },
      })
      // Handle the response here
      const data = response.data
      // Extract the result code from the response
      const tokenMatch = data.match(/<resultCode>(.*?)<\/resultCode>/)
      if (tokenMatch[1] === 'Ok') {
        // If the result code is "Ok," extract the customer profile ID
        const customerProfileID = data.match(
          /<customerProfileId>(.*?)<\/customerProfileId>/
        )
        // Call the createTokenAuthorize function with the obtained customer profile ID
        createTokenAuthorize(customerProfileID[1])
      } else {
        // If the result code is not "Ok," set payment success status to "fail"
        setOpen(true)
        setPaySuccess('fail')
        // Set a timeout to reset the payment success status after 5 seconds
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      }
    } catch (error) {
      // Handle errors here
      console.error(
        'Error:',
        error.response ? error.response.data : error.message
      )
    }
  }

  // Asynchronous function to create a payment profile in Authorize.Net
  /**
   * Initiates the process to create a customer payment profile with Authorize.Net and saves the order.
   * This function is specific to bank account payments using Authorize.Net.
   *
   * @param {string} cusID - The customer profile ID obtained from Authorize.Net.
   * @returns {Promise<void>} A Promise that resolves when the process is completed.
   */
  async function createTokenAuthorize(cusID) {
    // Set payment success status to "loading" to indicate an ongoing process
    setPaySuccess('loading')
    // Retrieve API credentials from the originalText object
    const API_LOGIN_ID = originalText?.public_key
    const TRANSACTION_KEY = originalText?.private_key

    // Set the API endpoint URL based on the chargeData mode
    const API_URL =
      chargeData?.mode === 'test'
        ? 'https://apitest.authorize.net/xml/v1/request.api'
        : 'https://api.authorize.net/xml/v1/request.api'

    // Prepare the XML request data with dynamic values
    const requestData = `
      <?xml version="1.0" encoding="UTF-8"?>
  <createCustomerPaymentProfileRequest xmlns="AnetApi/xml/v1/schema/AnetApiSchema.xsd">
    <merchantAuthentication>
    <name>${API_LOGIN_ID}</name>
    <transactionKey>${TRANSACTION_KEY}</transactionKey>
    </merchantAuthentication>
    <customerProfileId>${cusID}</customerProfileId>
    <paymentProfile>
      <payment>
        <bankAccount>
          <accountType>${paymentObj?.account_holder_type?.answer?.toLowerCase()}</accountType>
          <routingNumber>${paymentObj?.routing_number?.answer}</routingNumber>
          <accountNumber>${paymentObj?.account_number?.answer}</accountNumber>
          <nameOnAccount>${
            paymentObj?.account_holder_name?.answer
          }</nameOnAccount>
        </bankAccount>
      </payment>
      <defaultPaymentProfile>false</defaultPaymentProfile>
    </paymentProfile>
  </createCustomerPaymentProfileRequest>
      `

    try {
      // Make a POST request to the Authorize.Net API to create a customer payment profile
      const response = await axios.post(API_URL, requestData, {
        headers: {
          'Content-Type': 'text/xml',
        },
      })
      // Handle the response here

      const data = response.data
      // Extract the customer payment profile ID from the response
      const customerProfileID = data.match(
        /<customerPaymentProfileId>(.*?)<\/customerPaymentProfileId>/
      )
      SaveOrder(customerProfileID[1], 'authorize_net', cusID, 1)
    } catch (error) {
      // Handle errors here
      setPaySuccess('fail')
      setTimeout(() => {
        setPaySuccess(false)
      }, 5000)
      console.error(
        'Error:',
        error.response ? error.response.data : error.message
      )
    }
  }

  // Asynchronous function to obtain a customer profile ID and payment ID from Authorize.Net using credit card information
  /**
   * Initiates the process to create a customer profile with Authorize.Net using credit card information.
   * This function is specific to credit card payments with Authorize.Net.
   *
   * @returns {Promise<void>} A Promise that resolves when the process is completed.
   */
  async function getAuthorizeCardTrasId() {
    // Set payment success status to "loading" to indicate an ongoing process
    setPaySuccess('loading')

    // Retrieve API credentials and credit card information from the originalText object and other variables
    const API_LOGIN_ID = originalText?.public_key
    const TRANSACTION_KEY = originalText?.private_key
    const cardType1 = creditCardType.expirationDate(expDate)

    // Set the API endpoint URL based on the chargeData mode
    const API_URL =
      chargeData?.mode === 'test'
        ? 'https://apitest.authorize.net/xml/v1/request.api'
        : 'https://api.authorize.net/xml/v1/request.api'

    // Get the first two digits of the current year
    const currentYear = new Date().getFullYear()
    const firstTwoDigits = currentYear.toString().slice(0, 2)

    // Prepare the XML request data with dynamic values for creating a customer profile with credit card information
    const requestData = `
    <?xml version="1.0" encoding="UTF-8"?>
    <createCustomerProfileRequest xmlns="AnetApi/xml/v1/schema/AnetApiSchema.xsd"> 
    <merchantAuthentication>
    <name>${API_LOGIN_ID}</name>
    <transactionKey>${TRANSACTION_KEY}</transactionKey>
    </merchantAuthentication>
    <profile>
      <merchantCustomerId>${generateRandomString()}</merchantCustomerId>
      <description>Profile description here</description>
      <email>${paymentData?.email}</email>
      <paymentProfiles>
        <customerType>individual</customerType>
        <payment>
          <creditCard>
            <cardNumber>${cardNumber?.replaceAll(' ', '')}</cardNumber>
            <expirationDate>${firstTwoDigits}${cardType1.year}-${
      cardType1.month
    }</expirationDate>
          </creditCard>
          </payment>
      </paymentProfiles>
    </profile>
	<validationMode>testMode</validationMode>
  </createCustomerProfileRequest>
    `
    // Make a POST request to the Authorize.Net API to create a customer profile with credit card information
    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          'Content-Type': 'text/xml',
        },
      })
      // Handle the response here
      const data = response.data
      // Extract the result code from the response
      const tokenMatch = data.match(/<resultCode>(.*?)<\/resultCode>/)
      if (tokenMatch[1] === 'Ok') {
        // If the result code is "Ok," extract the customer profile ID
        const customerProfileID = data.match(
          /<customerProfileId>(.*?)<\/customerProfileId>/
        )
        // Call the createCustomerPayId function with the obtained customer profile ID
        createCustomerPayId(customerProfileID[1])
      } else {
        // If the result code is not "Ok," show an alert indicating an error
        Alert.alert('Error', 'Please try again after some time.')
      }
    } catch (error) {
      // Handle errors here
      console.error(
        'Error:',
        error.response ? error.response.data : error.message
      )
    }
  }

  // Asynchronous function to create a customer payment profile and obtain a customer payment profile ID from Authorize.Net
  /**
   * Creates a customer payment profile with Authorize.Net using the provided customer ID and credit card information.
   *
   * @param {string} customerID - The customer ID obtained from Authorize.Net.
   * @returns {Promise<void>} A Promise that resolves when the process is completed.
   */
  async function createCustomerPayId(customerID) {
    // Retrieve API credentials, credit card information, and other variables
    const API_LOGIN_ID = originalText?.public_key
    const TRANSACTION_KEY = originalText?.private_key
    const cardType1 = creditCardType.expirationDate(expDate)

    // Set the API endpoint URL based on the chargeData mode
    const API_URL =
      chargeData?.mode === 'test'
        ? 'https://apitest.authorize.net/xml/v1/request.api'
        : 'https://api.authorize.net/xml/v1/request.api'

    // Get the first two digits of the current year
    const currentYear = new Date().getFullYear()
    const firstTwoDigits = currentYear.toString().slice(0, 2)

    // Prepare the XML request data with dynamic values for creating a customer payment profile
    const requestData = `
    <?xml version="1.0" encoding="UTF-8"?>
    <createCustomerPaymentProfileRequest xmlns="AnetApi/xml/v1/schema/AnetApiSchema.xsd">
    <merchantAuthentication>
    <name>${API_LOGIN_ID}</name>
    <transactionKey>${TRANSACTION_KEY}</transactionKey>
    </merchantAuthentication>
    <customerProfileId>${customerID}</customerProfileId>
    <paymentProfile>
      <billTo>
        <firstName>test</firstName>
        <lastName>scenario</lastName>
        <company></company>
        <address>123 Main St.</address>
        <city>Bellevue</city>
        <state>WA</state>
        <zip>98004</zip>
        <country>USA</country>
        <phoneNumber>000-000-0000</phoneNumber>
        <faxNumber></faxNumber>
      </billTo>
      <payment>
        <creditCard>
        <cardNumber>${cardNumber?.replaceAll(' ', '')}</cardNumber>
        <expirationDate>${firstTwoDigits}${cardType1.year}-${
      cardType1.month
    }</expirationDate>
        </creditCard>
      </payment>
      <defaultPaymentProfile>false</defaultPaymentProfile>
    </paymentProfile>
  </createCustomerPaymentProfileRequest>
    `

    try {
      // Make a POST request to the Authorize.Net API to create a customer payment profile
      const response = await axios.post(API_URL, requestData, {
        headers: {
          'Content-Type': 'text/xml',
        },
      })
      // Handle the response data
      const data = response.data
      // Extract the result code from the response
      const tokenMatch = data.match(/<resultCode>(.*?)<\/resultCode>/)
      if (tokenMatch[1] === 'Ok') {
        // If the result code is "Ok," extract the customer payment profile ID
        const customerProfileID = data.match(
          /<customerPaymentProfileId>(.*?)<\/customerPaymentProfileId>/
        )
        SaveOrder(customerProfileID[1], 'authorize', customerID, 1)
      } else {
        Alert.alert('Error', 'Please try again after some time.')
      }
    } catch (error) {
      // Handle errors here
      console.error(
        'Error:',
        error.response ? error.response.data : error.message
      )
    }
  }

  // Function to handle changes in the entered card number
  /**
   * Handles changes in the entered card number and updates relevant state variables.
   *
   * @param {string} formatted - The formatted card number.
   * @returns {Promise<void>} A Promise that resolves when the process is completed.
   */
  const handleCardNumberChange = async (formatted) => {
    // Reset entered card information
    setEnteredCard('')
    // Get card type and BIN (Bank Identification Number)
    const cardType1 = creditCardType.number(formatted)
    const bin = formatted?.replaceAll(' ', '') // Example: Mastercard BIN

    // Check if there is an error in the card number
    const isErr =
      !isEmpty(formatted) && !isEmpty(cardType1) && !cardType1?.isValid
    setCardErr({
      ...cardErr,
      card: isErr,
    })

    // Determine and set the card type
    !isEmpty(cardType1?.card) && cardType1?.card?.type === 'american-express'
      ? setCardType('amex')
      : cardType1?.card?.type === 'diners-club'
      ? setCardType('diners')
      : setCardType('visa-or-mastercard')

    // Set the card number in the state
    setCardNumber(formatted)
    setEnteredCard(cardType1?.card?.niceType)
    // Check if the card is valid
    if (!isEmpty(cardType1) && cardType1?.isValid) {
      // Check if the entered card type differs from the selected payment sub-method type
      if (
        cardBrandSelect['payment_sub_method.type']?.toLowerCase() !==
        cardType1?.card?.niceType?.toLowerCase()
      ) {
        // Set the entered card information
        setEnteredCard(cardType1?.card?.niceType)
      } else {
        // const response = await axios.get(`https://lookup.binlist.net/${bin}`);
        // if (response) {
        //   if (response?.data) {
        //     response?.data?.type !== 'credit' &&
        //       paymentMethod[isShow]['payment_method.payment_method'] ===
        //         'Credit card' &&
        //       setEnteredCard('Credit');
        //     response?.data?.type !== 'debit' &&
        //       paymentMethod[isShow]['payment_method.payment_method'] ===
        //         'Debit Card' &&
        //       setEnteredCard('Debit');
        //   }
        // }
      }
    }
  }

  // Function to handle changes in the entered card expiration date
  /**
   * Handles changes in the formatted card expiration date and updates relevant state variables.
   *
   * @param {string} formatted - The formatted card expiration date.
   * @returns {void} Returns nothing.
   */
  const handleCardExpChange = (formatted) => {
    // Get card type based on the formatted expiration date
    const cardType1 = creditCardType.expirationDate(formatted)
    // Set an error in the card expiration date if it's not valid and not empty
    setCardErr({
      ...cardErr,
      expire: !cardType1?.isValid && !isEmpty(formatted),
    })
    // Set the formatted expiration date in the state
    setExpDate(formatted)
  }

  // Function to handle changes in the entered CVV
  /**
   * Handles changes in the formatted CVV (Card Verification Value) and updates relevant state variables.
   *
   * @param {string} formatted - The formatted CVV.
   * @returns {void} Returns nothing.
   */
  const handleCvvChange = (formatted) => {
    // Determine the expected CVV length based on the card type
    const cvvLength = cardType === 'amex' ? 4 : 3
    // Check if the entered CVV has the expected length
    const isErr = !isEmpty(formatted) && formatted?.length !== cvvLength
    const numericValue = formatted.replace(/[^0-9]/g, '')
    // Set an error in the CVV if it doesn't have the expected length
    setCardErr({
      ...cardErr,
      cvv: isErr,
    })
    // Set the formatted CVV in the state
    setCvv(numericValue)
  }

  // Function to make a PayPal payment
  /**
   * Makes a PayPal payment using the provided card information and handles the payment process.
   *
   * @param {string} code - The order code associated with the payment.
   * @returns {void} Returns nothing.
   * @throws {Error} Throws an error if there is an issue with the payment process.
   */
  const makePaymentPaypal = async (code) => {
    // Set the payment status to loading
    setPaySuccess('loading')

    // Extract card type and expiration date information
    const cardType1 = creditCardType.expirationDate(expDate)
    // get current year's first 2 digits
    const currentYear = new Date().getFullYear()
    const firstTwoDigits = currentYear.toString().slice(0, 2)
    try {
      // Retrieve client credentials from the environment
      const clientId = originalText?.public_key
      const clientSecret = originalText?.private_key
      // Prepare the payment source data
      const payment_source = {
        payment_source: {
          card: {
            number: cardNumber.replaceAll(' ', ''),
            expiry: `${firstTwoDigits}${cardType1?.year}-${cardType1?.month}`,
            cvv: cvv,
            name: paymentData?.name,
            verification_method: 'SCA_ALWAYS',
            experience_context: {
              brand_name: 'Aautipay',
              locale: 'en-US',
              return_url: `${liveUrl}redirect-3ds?val=success&redirect_url=${currentDomain?.origin}&order_code=${code}`,
              cancel_url: `${liveUrl}redirect-3ds?val=fail&redirect_url=${currentDomain?.origin}&order_code=${code}`,
            },
          },
        },
      }
      // Encode client credentials for authorization
      const credentials = `${clientId}:${clientSecret}`
      const credentialsBase64 = btoa(credentials)

      // Obtain an access token from PayPal
      const response = await axios.post(
        chargeData?.mode === 'test'
          ? 'https://api.sandbox.paypal.com/v1/oauth2/token'
          : 'https://api.paypal.com/v1/oauth2/token',
        'grant_type=client_credentials',
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${credentialsBase64}`,
          },
        }
      )
      // Extract the access token from the response
      const { access_token } = response.data

      // Create a card token with PayPal
      const cardTokenResponse = await axios.post(
        chargeData?.mode === 'test'
          ? 'https://api-m.sandbox.paypal.com/v3/vault/setup-tokens'
          : 'https://api-m.paypal.com/v3/vault/setup-tokens',
        payment_source,
        {
          headers: {
            'Content-Type': 'application/json',
            'PayPal-Request-Id': generateRandomString(),
            Authorization: `Bearer ${access_token}`,
          },
        }
      )

      // Check if the card token creation was successful
      if (
        cardTokenResponse?.status === 200 ||
        cardTokenResponse?.status === 201
      ) {
        // Set an interval to check the payment progress
        const interval = setInterval(() => {
          checkPaymentProgress(code, cardTokenResponse?.data?.id)
        }, 5000)
        Ref.current = interval
        // Extract the approval URL from the response
        const getUrlObj =
          isArray(cardTokenResponse?.data?.links) &&
          cardTokenResponse?.data?.links?.find((v) => v?.rel === 'approve')
        // Open a new window with the approval URL
        window?.open(getUrlObj?.href, '_blank', 'width=600,height=600')
      } else {
        // Set payment status to fail if there's an issue
        setPaySuccess('fail')
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      }
    } catch (error) {
      // Handle errors and set payment status to fail
      setPaySuccess('fail')
      setTimeout(() => {
        setPaySuccess(false)
      }, 5000)
      console.log('Error:', error.response.data)
    }
  }

  // Function to get the list of payment tokens for a user
  /**
   * Fetches the user's payment tokens from the server based on the selected payment method and updates the state accordingly.
   *
   * @async
   * @function
   * @returns {Promise<void>} A Promise that resolves once the card list is fetched and the state is updated.
   * @throws {Error} Throws an error if there is an issue with the API request or processing the response.
   */
  async function getCardList() {
    // Set the main loader to true
    setMainLoader(true)
    try {
      // Prepare data for the API request
      const data = {
        app_token: paymentData?.app_token,
        gateway_code: cardBrandSelect?.charge_object?.gateway_code,
        email: paymentData?.email,
        mode: chargeData?.mode,
        payment_method_id: cardBrandSelect?.charge_object?.payment_method_id,
        payment_sub_method_id: cardBrandSelect?.payment_sub_method_id,
        mobile: paymentData?.mobile,
      }

      // Make an API request to fetch user payment tokens
      const response = await getApiDataProgressPayment(
        `${liveUrl}fetch-user-payment-tokens`,
        'POST',
        JSON.stringify(data),
        chargeData?.auth_token
      )
      // Check if the API response is successful
      if (response?.status == false) {
        // Alert.alert(
        //   'Error',
        //   response?.message || 'Please try again. Something got wrong.'
        // )
      } else {
        // Extract and filter the payment tokens based on the payment method type
        const arr =
          type === 'ACH'
            ? response?.data
            : response?.data.filter(
                (v) =>
                  v?.cardType?.toLowerCase() ==
                  cardBrandSelect['payment_sub_method.type']?.toLowerCase()
              )
        // Set the customer ID, card list, and close the confirmation dialog
        setCusID(response?.customer_id)
        setCardList(response?.data)
        setConfirmSaveDialog(false)
      }
      // Set the main loader and confirmation loader to false
      setMainLoader(false)
      setCnfmLoader(false)
    } catch (error) {
      // Handle errors and set loaders to false
      setMainLoader(false)
      setCnfmLoader(false)
      console.log('error:', error)
    }
  }

  // Function to render a dropdown (select) input
  /**
   * Renders a dropdown (Select) component based on the provided object configuration and key.
   *
   * @param {Object} obj - The configuration object for rendering the dropdown.
   * @param {string} key - The key associated with the dropdown, typically used for identifying the dropdown in state.
   * @returns {ReactNode} The rendered dropdown component.
   */
  function renderDropDown(obj, key) {
    // Split the comma-separated values and create an array of name-value pairs
    const newV = obj.value?.split(',')?.map((it, x) => {
      return { name: it.trim(), value: it.trim() }
    })

    return (
      <FormControl fullWidth error={obj?.error}>
        <InputLabel id='demo-simple-select-label'>{obj.title}</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          sx={{
            '.css-e98cz7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
              {
                fontSize: '16px',
              },
            borderColor: '#9D9D9D !important',
          }}
          size='medium'
          fullWidth
          label={obj.title}
          value={obj.answer || ''}
          onChange={(e, v) => {
            changeValObj(e.target.value, key)
          }}
        >
          {newV.map((vv, idx) => (
            <MenuItem value={vv.value} key={idx}>
              {vv?.name}
            </MenuItem>
          ))}
        </Select>
        {obj.error ? <FormHelperText>{obj.errmsg}</FormHelperText> : ''}
      </FormControl>
    )
  }

  /**
   * Render a TextField component based on the given parameters.
   *
   * @param {Object} obj - An object containing configuration for the TextField.
   * @param {string} key - The key associated with the TextField.
   * @returns {JSX.Element} - The generated TextField component.
   */
  function renderTextField(obj, key) {
    return (
      <TextField
        size='medium'
        variant='outlined'
        sx={{
          '.css-1bfgl9n-MuiInputBase-input-MuiOutlinedInput-input': {
            fontSize: '16px',
          },
        }}
        className={styles.input}
        placeholder={obj.title}
        label={obj.title}
        value={obj?.answer}
        type={obj?.type}
        onChange={(e) => {
          const inputValue =
            obj?.type === 'number' && e?.nativeEvent?.data !== 'e'
              ? e.target.value
              : e.target.value
          if (
            (inputValue.length <= obj?.length && key !== 'account_number') ||
            (key === 'account_number' && inputValue.length <= obj?.max)
          ) {
            changeValObj(inputValue, key)
          }
        }}
        error={obj?.error}
        helperText={obj?.errmsg}
      />
    )
  }

  /**
   * Updates the answer, error status, and error message for a specific key in the payment object state.
   *
   * @param {any} val - The new value to set for the specified key in the payment object.
   * @param {string} key - The key in the payment object to be updated.
   * @returns {void}
   */
  const changeValObj = (val, key) => {
    // Create a copy of the current state
    const dummy_obj = { ...paymentObj }
    // Update the specified key in the copied object
    dummy_obj[key].answer = val
    dummy_obj[key].error = false
    dummy_obj[key].errmsg = ''

    // Set the state with the updated object
    setPaymentObj(dummy_obj)
  }

  const materialUITextFieldProps = {
    sx: {
      '.css-1bfgl9n-MuiInputBase-input-MuiOutlinedInput-input': {
        fontSize: '16px',
      },
    },
    variant: 'outlined',
    size: 'medium',
    label: 'Card number',
    className: styles.cardTextField,
    placeholder: 'Card number',
    error: cardErr?.card,
    // || !isEmpty(enteredCard),
  }

  const materialUIExpTextFieldProps = {
    sx: {
      '.css-1bfgl9n-MuiInputBase-input-MuiOutlinedInput-input': {
        fontSize: '16px',
      },
    },
    variant: 'outlined',
    size: 'medium',
    label: 'Expire date',
    className: styles.cardTextField,
    placeholder: 'Expire date',
    error: cardErr?.expire,
  }

  /**
   * Formats a string representing a month and year into a valid MM/YY format.
   * If the input string is empty, an empty string is returned.
   *
   * @param {string} val - The input string representing a month and year.
   * @returns {string} - The formatted string in MM/YY format, or an empty string if the input is empty.
   */
  const format = (val) => {
    if (val === '') return ''
    let month = val.substring(0, 2)
    const year = val.substring(2, 4)

    if (month.length === 1 && month[0] > 1) {
      month = `0${month[0]}`
    } else if (month.length === 2) {
      // set the lower and upper boundary
      if (Number(month) === 0) {
        month = `01`
      } else if (Number(month) > 12) {
        month = '12'
      }
    }

    return `${month}/${year}`
  }

  /**
   * Validates the payment object fields and sets error states accordingly.
   * If all fields are valid, it triggers the confirmation dialog.
   */
  const Validation = () => {
    let valid = true
    const dummy_obj = { ...paymentObj }

    // Iterate through each field in the payment object
    Object.keys(dummy_obj).map((key) => {
      const item = dummy_obj[key]
      if (!item?.answer || isEmpty(item?.answer)) {
        valid = false
        dummy_obj[key].error = true
        dummy_obj[key].errmsg = `Please enter ${item?.title}`
      } else if (
        (key === 'account_number' &&
          (item?.answer.length < item?.min ||
            item?.answer.length > item?.max)) ||
        (key === 'routing_number' && item?.answer.length !== item?.length)
      ) {
        valid = false
        dummy_obj[key].error = true
        dummy_obj[key].errmsg = `Please enter valid ${item?.title}`
      }
    })
    // Set the updated payment object with error states
    setPaymentObj(dummy_obj)
    // If all fields are valid, trigger the confirmation dialog
    if (valid) {
      setConfirmDialog(true)
      setConfirmType({
        data: { cardBrandSelect },
        type: 'button',
      })
    }
  }

  /**
   * Removes a saved card associated with the current customer.
   * Initiates an API request to delete the card and updates the card list upon success.
   * @async
   * @function RemoveSavedCard
   * @throws {Error} Throws an error if the API request fails.
   * @returns {Promise<void>} A Promise that resolves once the card is successfully removed.
   */
  async function RemoveSavedCard() {
    setCnfmLoader(true)
    try {
      const data = {
        customer_id: cusMainID,
        token: confirmSaveType?.token,
      }

      const response = await getApiDataProgressPayment(
        `${liveUrl}delete-user-card/${confirmSaveType?.id}`,
        'POST',
        JSON.stringify(data),
        chargeData?.auth_token
      )
      if (response?.status == false) {
        // Alert.alert(
        //   "Error",
        //   response?.message || "Please try again. Something got wrong."
        // );
      } else {
        getCardList()
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  const renderImage = (type = enteredCard) => {
    const cardType = type?.toLowerCase()
    return cardType === 'visa'
      ? Constants.visa
      : cardType === 'mastercard'
      ? Constants.masterCard
      : cardType === 'rupay'
      ? Constants.ruPay
      : cardType === 'american express'
      ? Constants.americanExpress
      : cardType === 'jcb'
      ? Constants.jcb
      : cardType === 'diners club'
      ? Constants.dinersClub
      : cardType === 'discover'
      ? Constants.discover
      : ''
  }

  async function updatePaymentMethod(token, type1, cusID, isNew, code) {
    setPaySuccess('loading')
    const cardType1 = creditCardType.expirationDate(expDate)
    // Determine if the payment type is ACH
    const isBank = type === 'ACH'
    // Determine the gateway type from the charge object
    const isType = cardBrandSelect?.charge_object?.charges_obj?.gateway_name

    // Initialize an object to store final payment data
    const data =
      type1 === 'razorpay'
        ? {
            method: 'card',
            card: {
              number: cardNumber.replaceAll(' ', ''),
              cvv: cvv,
              expiry_month: cardType1?.month,
              expiry_year: cardType1?.year,
              name: paymentData?.name,
            },
          }
        : { token }
    let final_data = {
      amount: {
        amount: paymentData?.amount,
        final_amount: cardBrandSelect?.charge_object?.charges_obj?.final_amount,
      },
      ...data,
    }
    // Check if the payment type is ACH
    if (isBank) {
      // Check if the gateway type is Adyen or Authorize.net
      if (isType === 'adyen' || isType === 'authorize_net') {
        final_data.token = token
        final_data.customer_id = !isEmpty(cusID) ? cusID : cusMainID
      } else {
        // For other ACH types, include bank information in the final data
        final_data.bank = {
          bank_id: isNew === 0 ? token : token?.bank_account?.id,
        }
        // If it's a new bank, include the token in the final data
        if (isNew === 1) {
          final_data.bank.token = token?.id
        }
      }
    } else {
      // If the payment type is not ACH, include customer ID and token in the final data
      if (type1 === 'braintree' || type1 === 'adyen' || type1 === 'authorize') {
        final_data.customer_id = cusID
      }
      // final_data.token = token
    }

    // Encrypt the final data using AES encryption
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(final_data),
      '470cb677d807b1e0017c50b'
    ).toString()

    // Prepare data for the API request
    const Ddata = {
      data: ciphertext,
      order_code: paymentData?.recurrenceObj?.order_code,
      is_new: isNew,
      redirect_url: currentDomain?.origin,
      remember_me: checked ? 1 : 0,
    }

    try {
      // Make a POST request to the custom-checkout endpoint for payment processing
      const response = await getApiDataProgressPayment(
        `${liveUrl}update-subscription-payment`,
        'POST',
        JSON.stringify(Ddata),
        chargeData?.auth_token
      )

      // Check the response status and take appropriate actions
      if (isUndefined(response) || response?.status === false) {
        // If the response indicates an error, set payment success status to "fail" with an error message
        setPaySuccess(Strings.updateFailed, response?.message?.toString())
        // Set a timeout to reset the payment success status after 5 seconds
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else if (response?.status === 'processing') {
        // If the status is "processing," set up an interval to check payment progress
        const interval = setInterval(() => {
          checkPaymentProgress(code)
        }, 5000)
        Ref.current = interval
      } else {
        // If the response indicates success, check if there is a URL
        // If there is no URL, set payment success status to "success" and trigger onPaymentDone
        setPaySuccess(Strings.updatePayMethod, response?.message)
        onPaymentDone()
      }
      setListLoader('')
    } catch (error) {
      // Handle any errors that occurred during the payment processing
      console.log('📌 ⏩ file: index.js:227 ⏩ paymentApi ⏩ error:', error)
      setListLoader('')
    }
  }

  const onClickPay = (confirmType) => {
    if (confirmType.type === 'card') {
      const item = confirmType.data.item
      const index = confirmType.data.index
      const gateway = cardBrandSelect?.charge_object?.charges_obj?.gateway_name
      if (
        cardBrandSelect?.charge_object?.charges_obj?.gateway_name === 'adyen' ||
        cardBrandSelect?.charge_object?.charges_obj?.gateway_name ===
          'authorize_net'
      ) {
        SaveOrder(item?.token, 'adyen', cusMainID, 0)
      } else {
        if (updateSubPayMethod) {
          updatePaymentMethod(item?.token, gateway, '', 0)
        } else {
          SaveOrder(item?.token, gateway, '', 0)
        }
      }
      setListLoader(index)
      setPaySuccess('loading')
    } else if (confirmType.type === 'button') {
      const cardBrandSelect = confirmType.data.cardBrandSelect
      if (type !== 'ACH') {
        if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name === 'stripe'
        ) {
          createTokenStripe()
        } else if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name === 'adyen'
        ) {
          SaveOrder('', 'adyen', '', 1)
        } else if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name ===
          'braintree'
        ) {
          SaveOrder('', 'braintree', '', 1)
        } else if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name ===
          'authorize_net'
        ) {
          !isEmpty(cusMainID)
            ? createCustomerPayId(cusMainID)
            : getAuthorizeCardTrasId()
        } else if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name === 'paypal'
        ) {
          SaveOrder('', 'paypal', '', 1)
        } else if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name ===
          'razorpay'
        ) {
          // createTokenrazorPay()
          SaveOrder('', 'razorpay', '', 1)
        }
      } else {
        if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name === 'stripe'
        ) {
          createAchTokenStripe()
        } else if (
          cardBrandSelect?.charge_object?.charges_obj?.gateway_name ===
          'authorize_net'
        ) {
          !isEmpty(cusMainID)
            ? createTokenAuthorize(cusMainID)
            : getAuthorizeTrasId()
        } else {
          SaveOrder('', 'adyen', '', 1)
        }
      }
    }
  }

  const btnText = updateSubPayMethod
    ? 'Update'
    : `${changeBtnText} ${currency_symbol[paymentData?.currency]} ${Number(
        finalAmount
      )?.toFixed(2)}`

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
      }}
    >
      {type !== 'ACH' && (
        <Tabs
          value={cardBrandSelect}
          variant='scrollable'
          sx={{
            '& .MuiTabs-flexContainer': {
              // flexWrap: "wrap",
              // overflow: 'hidden'
            },
          }}
          indicatorColor={''}
          style={{ marginBottom: 20 }}
          scrollButtons={false}
          // onChange={(event, newValue) => {
          //   setTabValue(newValue)
          // }}
        >
          {paymentType.map((dd, idx) => {
            return (
              <Tab
                value={dd}
                sx={{
                  '.MuiBox-root': {
                    padding: '24px 0 !important',
                  },
                  '.MuiTab-iconWrapper': {
                    marginBottom: '0px',
                  },
                }}
                disabled={true}
                icon={
                  <img
                    src={dd['payment_sub_method.logo']}
                    style={{
                      height: 40,
                      width: sm ? 45 : 60,
                      objectFit: 'contain',
                    }}
                    alt='logo'
                  />
                }
                label={
                  chargeData?.isPaymentGateWay &&
                  !noCharge && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // marginLeft: 6,
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          textTransform: 'capitalize',
                          color: 'black',
                          fontSize: 12,
                          marginRight: 6,
                        }}
                      >
                        {`${
                          Number(
                            dd?.charge_object?.charges_obj?.transaction_per || 0
                          ) +
                          Number(
                            dd?.charge_object?.charges_obj
                              ?.currency_conversion_percentage || 0
                          ) +
                          Number(
                            dd?.charge_object?.charges_obj
                              ?.international_charge_percentage || 0
                          )
                        }% fee`}{' '}
                        {dd?.charge_object?.charges_obj?.fixed_fee_amount &&
                          `+ ${
                            currency_symbol[
                              dd?.charge_object?.charges_obj?.fixed_fee_currency
                            ]
                          }${
                            dd?.charge_object?.charges_obj?.fixed_fee_amount ||
                            ''
                          }`}
                      </span>

                      <InfoOutlined
                        aria-describedby={PopoverID}
                        style={{
                          fontSize: 18,
                          color: '#2D2D2D',
                        }}
                        onClick={(event) => {
                          setLongPressData(dd?.charge_object?.charges_obj)
                          handlePopoverOpen(true)
                        }}
                      />
                    </div>
                  )
                }
              />
            )
          })}
        </Tabs>
      )}
      {mainLoader ? (
        <div
          style={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={30} style={{ color: primaryColor }} />
        </div>
      ) : (
        <>
          {isArray(cardList) && !isEmpty(cardList) && (
            <div style={{ marginBottom: 10 }}>
              {/* Saved card */}
              <Typography className={styles.subTitle} fontSize={sm ? 14 : ''}>
                Saved {type === 'ACH' ? 'bank' : 'card'} details
              </Typography>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                {cardList.map((item, index) => {
                  return (
                    <Grid
                      item
                      sx={{
                        margin: '6px 0',
                        width: '100%',
                        height: sm ? '48px' : 'unset',
                      }}
                      className={styles.savedCardGrid}
                      sm={12}
                      md={5.9}
                      onClick={() => {
                        if (isRecurring || updateSubPayMethod) {
                          onClickPay({ data: { item, index }, type: 'card' })
                        } else {
                          setConfirmDialog(true)
                        }
                        setConfirmType({ data: { item, index }, type: 'card' })
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: type === 'ACH' ? 12 : 0,
                        }}
                      >
                        {type === 'ACH' ? (
                          <AccountBalance
                            style={{ marginRight: 10, color: '#8E8E8E' }}
                          />
                        ) : (
                          <img
                            src={renderImage(item?.cardType)}
                            style={{
                              height: 40,
                              width: 50,
                              objectFit: 'contain',
                              padding: '0 10px',
                            }}
                            alt='logo'
                          />
                        )}
                        <Typography
                          fontSize={14}
                        >{`XXXX XXXX XXXX ${item?.last4}`}</Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography fontWeight='bold' fontSize={14}>
                          {currency_symbol[paymentData?.currency]}
                          {Number(finalAmount)?.toFixed(2)}
                        </Typography>
                        {listLoader === index ? (
                          <CircularProgress style={{ color: primaryColor }} />
                        ) : (
                          <ArrowRightIcon
                            className={styles.savedCardGridArrow}
                          />
                        )}
                      </div>
                      <Cancel
                        style={{
                          position: 'absolute',
                          right: -10,
                          top: -10,
                          color: 'red',
                          cursor: 'pointer',
                          zIndex: 10,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setConfirmSaveDialog(true)
                          setConfirmSaveType({
                            token: item?.token,
                            id: item?.id,
                          })
                        }}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          )}
          <Box margin='5px 0 20px 0px'>
            <Typography className={styles.subTitle} fontSize={sm ? 14 : ''}>
              Enter {type === 'ACH' ? 'bank' : 'card'} details
            </Typography>
            <Grid
              container
              justifyContent='space-between'
              alignItems='baseline'
            >
              {type === 'ACH' ? (
                !isEmpty(paymentObj) &&
                Object.keys(paymentObj).map((key, index) => {
                  const item = paymentObj[key]
                  return (
                    <Grid
                      sx={5.9}
                      lg={5.9}
                      md={2.9}
                      sm={12}
                      xs={12}
                      margin='10px 0'
                      key={index}
                    >
                      {paymentObj[key].type !== 'dropdown'
                        ? renderTextField(item, key)
                        : renderDropDown(item, key)}
                    </Grid>
                  )
                })
              ) : (
                <>
                  <Grid
                    lg={5.9}
                    md={5.9}
                    sm={12}
                    mb={2}
                    xs={12}
                    margin='10px 0'
                  >
                    <PatternFormat
                      format={
                        cardType === 'amex'
                          ? '#### ###### #####'
                          : '#### #### #### ####'
                      }
                      value={cardNumber}
                      onValueChange={(values) => {
                        handleCardNumberChange(values?.value)
                      }}
                      valueIsNumericString
                      customInput={TextField}
                      {...materialUITextFieldProps}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <img
                              src={renderImage(enteredCard)}
                              style={{ height: 20 }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    lg={2.9}
                    md={2.9}
                    sm={12}
                    mb={2}
                    xs={12}
                    margin='10px 0'
                  >
                    <NumberFormatBase
                      {...props}
                      format={format}
                      customInput={TextField}
                      value={expDate}
                      onValueChange={(values) => {
                        handleCardExpChange(values?.formattedValue)
                      }}
                      {...materialUIExpTextFieldProps}
                    />
                  </Grid>
                  <Grid lg={2.9} md={2.9} sm={12} xs={12} margin='10px 0'>
                    <TextField
                      sx={{
                        '.css-1bfgl9n-MuiInputBase-input-MuiOutlinedInput-input':
                          {
                            fontSize: '16px',
                          },
                      }}
                      variant='outlined'
                      size='medium'
                      label='CVV'
                      className={styles.cardTextField}
                      placeholder='CVV'
                      name='cvc'
                      value={cvv}
                      error={cardErr?.cvv}
                      onChange={(e) => handleCvvChange(e.target.value)}
                      inputProps={{ maxLength: cardType === 'amex' ? 4 : 3 }}
                    />
                  </Grid>

                  {enteredCard && (
                    <></>
                    //     <div
                    //       style={{
                    //         flexDirection: 'row',
                    //         alignItems: 'center',
                    //         marginTop: 4,
                    //       }}
                    //     >
                    //       <Typography
                    //         style={{
                    //           color: 'red',
                    //         }}
                    //       >
                    //         {`You have entered ${enteredCard} card, Please enter valid card.`}
                    //         {/* <TouchableOpacity activeOpacity={0.7} onPress={() => {}}>
                    //   <Text
                    //     style={{
                    //       textDecorationLine: 'underline',
                    //       color: '#0068EF',
                    //       // marginTop: -4,
                    //     }}>
                    //     click here
                    //   </Text>
                    // </TouchableOpacity>
                    // <Text
                    //   style={{
                    //     color: 'red',
                    //   }}>{` to make payment by ${enteredCard} card`}</Text> */}
                    //       </Typography>
                    //     </div>
                  )}
                </>
              )}
            </Grid>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#9D9D9D',
                margin: '6px 0px 20px 0px',
              }}
            >
              <GppGoodIcon style={{ marginRight: '5px' }} />
              <Typography>
                {`We are not storing any ${
                  type === 'ACH' ? 'bank' : 'card'
                } details, So your data will be
                secured end to end.`}
              </Typography>
            </div>
            {cardBrandSelect?.charge_object?.charges_obj?.gateway_name !==
              'razorpay' && (
              <div style={{ display: 'flex' }}>
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  style={{ marginRight: 8 }}
                />
                <Typography fontWeight={'bold'} fontSize={sm ? 14 : 'unset'}>
                  Securely save my information for 1-click checkout
                </Typography>
              </div>
            )}
            <Box>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Typography fontWeight={"bold"}>Pricing Breakdown</Typography>
                {showBreakdown ? (
                  <ArrowDropUpIcon
                    style={{
                      fontSize: "35px",
                      color: primaryColor,
                    }}
                    onClick={() => setShowBreakdown(false)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    style={{
                      fontSize: "35px",
                      color: primaryColor,
                    }}
                    onClick={() => setShowBreakdown(true)}
                  />
                )}
              </div> */}

              <div
                className={styles.pricingBreakdown}
                style={{
                  display: showBreakdown ? 'block' : 'none',
                }}
              >
                <Grid
                  container
                  justifyContent='space-between'
                  marginBottom={0.6}
                >
                  <span className='amount-type'>Amount</span>
                  <span className={styles.pricingBreakdown_amount}>
                    {currency_symbol[paymentData?.currency]}
                    {chargeData?.withoutChargeAmount.toFixed(2)}
                  </span>
                </Grid>

                {isArray(chargeData?.mainChargeData) &&
                  !isEmpty(chargeData?.mainChargeData) &&
                  chargeData?.mainChargeData?.map((item, index) => {
                    const amountToAdd =
                      (item?.value * paymentData?.amount) / 100
                    return (
                      <Grid
                        container
                        justifyContent='space-between'
                        marginBottom={0.6}
                      >
                        <span className='amount-type'>{item?.name}</span>
                        <span className={styles.pricingBreakdown_amount}>
                          {currency_symbol[paymentData?.currency]}
                          {item?.slug === 'payment_gateway_fee'
                            ? Number(paymentGatwayFee)?.toFixed(2)
                            : amountToAdd?.toFixed(2)}
                        </span>
                      </Grid>
                    )
                  })}
                <Grid
                  container
                  justifyContent='space-between'
                  className={styles.pricingBreakdown_final}
                >
                  <span className='amount-type'>Final amount</span>
                  <span className={styles.pricingBreakdown_amount}>
                    {currency_symbol[paymentData?.currency]}
                    {Number(finalAmount)?.toFixed(2)}
                  </span>
                </Grid>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  sx={{
                    opacity: isDisable ? 0.6 : 1,
                    background: isGradientButton
                      ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                      : primaryColor,
                    ':hover': {
                      background: isGradientButton
                        ? `linear-gradient(124deg, ${linearColorsName[0]}, ${linearColorsName[1]})`
                        : primaryColor,
                    },

                    fontSize: sm ? 16 : '20px',
                  }}
                  className={styles.payButton}
                  disabled={isDisable}
                  onClick={async () => {
                    if (type === 'ACH') {
                      Validation()
                    } else {
                      if (isRecurring || updateSubPayMethod) {
                        onClickPay({
                          data: { cardBrandSelect },
                          type: 'button',
                        })
                      } else {
                        setConfirmDialog(true)
                      }
                      setConfirmType({
                        data: { cardBrandSelect },
                        type: 'button',
                      })
                    }
                  }}
                >
                  {btnText}
                </Button>
              </div>
            </Box>
          </Box>
        </>
      )}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity='error'
          sx={{ width: '100%' }}
        >
          Please try again after some time!
        </Alert>
      </Snackbar>
      <ConfirmDialog
        title={`Are you sure you want to make payment with this ${
          type === 'ACH' ? 'Bank' : 'Card'
        }?`}
        changeBtnText={changeBtnText}
        visible={confirmDialog}
        chargeData={chargeData}
        paymentData={paymentData}
        primaryColor={primaryColor}
        finalAmount={finalAmount}
        noCharge={noCharge}
        paymentGatwayFee={paymentGatwayFee}
        linearColorsName={linearColorsName}
        isGradientButton={isGradientButton}
        handleModal={() => {
          onClickPay(confirmType)
        }}
      />

      <ConfirmDialog
        title={`Are you sure you want to remove this ${
          type === 'ACH' ? 'Bank' : 'Card'
        }?`}
        visible={confirmSaveDialog}
        changeBtnText=''
        linearColorsName={linearColorsName}
        isGradientButton={isGradientButton}
        primaryColor={primaryColor}
        noCharge={noCharge}
        btnLoad={cnfmLoader}
        handleModal={(bool) => {
          if (bool) {
            RemoveSavedCard()
          } else {
            setConfirmSaveDialog(false)
          }
        }}
      />

      <Modal
        open={openPopover}
        onClose={() => {
          !isEmpty(longPressData) && setLongPressData(null)
          !isEmpty(longPressData) && handlePopoverOpen(false)
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 5,
            boxShadow: 24,
            p: 1,
          }}
        >
          <div
            style={{
              width: '100%',
              padding: '10px',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '95%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 12,
                }}
              >
                <img
                  src={
                    longPressData?.gateway_name === 'stripe'
                      ? 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png'
                      : longPressData?.gateway_name === 'authorize_net'
                      ? 'https://upload.wikimedia.org/wikipedia/commons/1/16/Authorize.net_logo.png'
                      : longPressData?.gateway_name === 'braintree'
                      ? 'https://www.appvizer.com/media/application/439/cover/987/cover-braintree'
                      : longPressData?.gateway_name === 'paypal'
                      ? 'https://pngimg.com/uploads/paypal/paypal_PNG22.png'
                      : longPressData?.gateway_name === 'razorpay'
                      ? 'https://cdn.iconscout.com/icon/free/png-256/free-razorpay-1649771-1399875.png'
                      : longPressData?.gateway_name === 'adyen'
                      ? 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Adyen_Corporate_Logo.svg/2560px-Adyen_Corporate_Logo.svg.png'
                      : 'https://pngimg.com/uploads/paypal/paypal_PNG22.png'
                  }
                  style={{
                    height: 30,
                    objectFit: 'contain',
                    width: 40,
                  }}
                  alt='img'
                />

                <Typography
                  style={{
                    marginHorizontal: 6,
                    fontSize: 14,
                    color: '#000',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                  numberOfLines={2}
                >
                  {displayName}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                Amount:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {currency_symbol[paymentData?.currency]}
                {paymentData?.amount.toFixed(2)}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                Transaction percentage:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {parseFloat(longPressData?.transaction_per)?.toFixed(2) ||
                  '0.00'}
                %
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                Transaction Amount:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {currency_symbol[paymentData?.currency]}
                {parseFloat(longPressData?.transaction_amount)?.toFixed(2) ||
                  '0.00'}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                Fixed fee:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {currency_symbol[paymentData?.currency]}
                {longPressData?.fixed_fee_amount?.toFixed(2) || '0.00'}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                International Transaction percentage:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {longPressData?.international_charge_percentage?.toFixed(2) ||
                  '0.00'}
                %
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                International Transaction amount:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {currency_symbol[paymentData?.currency]}
                {!isEmpty(longPressData?.international_charge_amount)
                  ? longPressData?.international_charge_amount?.toFixed(2)
                  : '0.00'}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                Tax percentage:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {!isEmpty(longPressData?.tax_percentage_per)
                  ? parseFloat(longPressData?.tax_percentage_per)?.toFixed(2)
                  : '0.00'}
                %
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                }}
                numberOfLines={2}
              >
                Tax amount:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: '#000',
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {currency_symbol[paymentData?.currency]}
                {longPressData?.tax_percentage_amount?.toFixed(2) || '0.00'}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                textTransform: 'capitalize',
                justifyContent: 'space-between',
                fontSize: 12,
                width: '95%',
              }}
            >
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: primaryColor,
                }}
                numberOfLines={2}
              >
                Final Amount:
              </Typography>
              <Typography
                style={{
                  marginHorizontal: 6,
                  fontSize: 14,
                  color: primaryColor,
                  fontWeight: 'bold',
                }}
                numberOfLines={2}
              >
                {currency_symbol[paymentData?.currency]}
                {longPressData?.final_amount?.toFixed(2)}
              </Typography>
            </Grid>
          </div>
        </Box>
      </Modal>
    </Box>
  )
}

export default CustomCard
